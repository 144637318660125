import withStyles from 'isomorphic-style-loader/withStyles';
import MarkdownIt from 'markdown-it';
import { useState } from 'react';
import { Segment } from 'semantic-ui-react';

import s from './CustomMessage.scss';

const md = MarkdownIt({ linkify: true, typographer: true });

// If you change these, update example_configs/config-ui.yaml and the helm chart
const titleLimit = 250;
const messageLimit = 10000;

export const useCustomMessage = () => {
  const [content] = useState(
    process.env.BROWSER ? window.App.customMessage : false,
  );

  return { content, hasCustomMessage: !!content };
};

/**
 * Renders a custom message, if supplied in config.
 *
 * The message can be supplied as markdown, which is transformed to HTML.
 */
const CustomMessage = () => {
  const { content } = useCustomMessage();

  if (!content) {
    return null;
  }

  const title = content.title ? (
    <h4
      className={s.title}
      // eslint-disable-next-line react/no-danger -- Alas, danger is required
      dangerouslySetInnerHTML={{
        __html: md.renderInline(content.title.substring(0, titleLimit)),
      }}
    />
  ) : null;

  const message = content.message ? (
    <div
      className={s.message}
      // eslint-disable-next-line react/no-danger -- Alas, danger is required
      dangerouslySetInnerHTML={{
        __html: md.render(content.message.substring(0, messageLimit)),
      }}
    />
  ) : null;

  return (
    <Segment.Group>
      <Segment className={s.segment}>
        {title}
        {message}
      </Segment>
    </Segment.Group>
  );
};

export default withStyles(s)(CustomMessage);
