import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'deepmerge';

import {
  type ViewTypes,
  type ViewType,
  type ViewState,
  initialTableViewProps,
} from '@models';

export type ViewsState = ViewTypes;

export const initialState: ViewsState = {
  gettingStarted: {},
  sbomGroupsPagination: {
    ...initialTableViewProps,
    page: -1, // Defer control of the initial fetch
    pageSize: 100,
    totalRows: 0,
    ids: [],
  },
  sbomGroupsFieldPagination: {
    ...initialTableViewProps,
    page: -1, // Defer control of the initial fetch
    pageSize: 100,
    totalRows: 0,
    ids: [],
  },
  sbomsPagination: {
    ...initialTableViewProps,
    page: -1, // Defer control of the initial fetch
    sort: { updatedAt: true },
    totalRows: 0,
    pageIds: {},
    groupIds: [],
    requestSource: 'index',
  },
  sbomGroupSBOMsPagination: {
    ...initialTableViewProps,
    page: -1, // Defer control of the initial fetch
    sort: { updatedAt: true },
    totalRows: 0,
    pageIds: {},
    groupIds: [],
    requestSource: 'group',
  },
  systemIntegrationsList: initialTableViewProps,
};

const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {
    setViewState: {
      reducer<T extends ViewType = ViewType>(
        state: ViewsState,
        action: PayloadAction<{
          type: T;
          state: Partial<ViewState<T>>;
          options?: { isDeepMerge?: boolean };
        }>,
      ) {
        const { type, state: viewState } = action.payload;

        if (action.payload.options?.isDeepMerge) {
          // deepmerge is optional to allow more granular control by default
          // TODO: Consider splitting this to a separate action
          state[type] = merge(state[type] || {}, viewState, {
            arrayMerge: (_, source) => source,
          });
          return;
        }

        // Default to shallow merge so that nested structures can be overwritten
        Object.assign(state[type] || {}, viewState);
      },
      prepare<T extends ViewType = ViewType>(
        type: T,
        state: Partial<ViewState<T>>,
        options?: { isDeepMerge?: boolean },
      ) {
        return {
          payload: {
            type,
            state,
            options,
          },
        };
      },
    },
  },
});

export const { setViewState } = viewsSlice.actions;

export default viewsSlice.reducer;
