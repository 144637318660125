import type { EntityState } from '@reduxjs/toolkit';
import {
  createSelector,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import type {
  CreateSBOMRequestBody,
  CreateSBOMResponse,
  EntityServiceResponse,
  GetSBOMResponse,
  GetSBOMsResponse,
  GetSBOMsThunkParams,
  SBOMLatestRevisionRef,
  SBOMRecord,
  WithAsyncState,
} from '@models';
import {
  createAsyncAdapter,
  createAsyncReducers,
  createEntityAction,
  getEntityActions,
} from '@redux/common';
import type { RootState } from '@redux/store';
import { setViewState } from '@redux/views.slice';

const sliceName = 'sboms';

const sbomsLatestRevisionAdapter = createEntityAdapter<SBOMLatestRevisionRef>({
  selectId: ({ uuid }) => uuid,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

const sbomsByRevisionAdapter = createEntityAdapter<SBOMRecord>({
  selectId: ({ revisionUuid }) => revisionUuid,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

const asyncAdapter = createAsyncAdapter();

export type SBOMsState = WithAsyncState<{
  latest: EntityState<SBOMLatestRevisionRef>;
  revisions: EntityState<SBOMRecord>;
}>;

const entities = {
  fetchSBOMs: createEntityAction<
    GetSBOMsThunkParams,
    GetSBOMsResponse,
    SBOMsState
  >({
    actionName: 'sboms/getList',
    servicePath: '/service/sboms',
    debounce: true,
    customRequest: (request, path, { requestSource, ...params }) =>
      request.get(path).query(params),
    onSuccess: ({ body: { data }, totalRows }, params, { dispatch }) => {
      // API pagination is 1-based, table is 0-based
      const pageIndex =
        typeof params.page === 'undefined' ? 0 : params.page - 1;

      dispatch(
        setViewState(
          params.requestSource === 'group'
            ? 'sbomGroupSBOMsPagination'
            : 'sbomsPagination',
          {
            totalRows,
            pageIds: {
              [pageIndex]: data.sbomIds,
            },
          },
          { isDeepMerge: true },
        ),
      );
    },
    reducerOptions: {
      fulfilled: (state, action) => {
        const {
          body: { data },
        } = action.payload;

        sbomsByRevisionAdapter.upsertMany(state.revisions, data.sboms);
        sbomsLatestRevisionAdapter.upsertMany(
          state.latest,
          data.latestRevisions,
        );
      },
    },
  }),
  fetchSBOMById: createEntityAction<
    { sbomId: string; revisionId?: string },
    GetSBOMResponse,
    SBOMsState
  >({
    actionName: 'sboms/getById',
    servicePath: '/service/sboms/:sbomId/:revisionId?',
    reducerOptions: {
      fulfilled: (state, action) => {
        sbomsByRevisionAdapter.upsertOne(
          state.revisions,
          action.payload.body.data,
        );

        const { uuid, updatedAt, revisionHistory } = action.payload.body.data;

        // TODO: It might make more sense to store the SBOM and Revision objects separately instead of having to drill into a Revision to get SBOM-level data
        sbomsLatestRevisionAdapter.upsertOne(state.latest, {
          uuid,
          updatedAt,
          revisionUuid: revisionHistory[0].uuid,
        });
      },
    },
  }),
  createSBOM: createEntityAction<
    CreateSBOMRequestBody,
    CreateSBOMResponse,
    SBOMsState
  >({
    actionName: 'sboms/create',
    servicePath: '/service/sboms',
    verb: 'post',
    reducerOptions: {
      fulfilled: (state, action) => {
        const { uuid, revisionUuid, updatedAt } = action.payload.body.data;

        sbomsByRevisionAdapter.addOne(
          state.revisions,
          action.payload.body.data,
        );
        sbomsLatestRevisionAdapter.upsertOne(state.latest, {
          uuid,
          revisionUuid,
          updatedAt,
        });
      },
      rejected: state => {
        // Drop the error state on a POST so that it can be unwrapped in the
        // form submit handler
        state.error = undefined;
      },
    },
  }),
  deleteSBOMById: createEntityAction<
    { sbomId: string; revisionId?: string },
    EntityServiceResponse<void>,
    SBOMsState
  >({
    actionName: 'sboms/deleteById',
    servicePath: '/service/sboms/:sbomId/:revisionId?',
    verb: 'delete',
    reducerOptions: {
      fulfilled: (state, action) => {
        const { revisionId, sbomId } = action.meta.arg;

        if (revisionId) {
          sbomsByRevisionAdapter.removeOne(state.revisions, revisionId);
          return;
        }

        sbomsLatestRevisionAdapter.removeOne(state.latest, sbomId);
      },
    },
  }),
};

export const { fetchSBOMs, fetchSBOMById, createSBOM, deleteSBOMById } =
  getEntityActions(entities);

export const initialState: SBOMsState = asyncAdapter.getInitialState({
  latest: sbomsLatestRevisionAdapter.getInitialState({
    filteredIds: [],
  }),
  revisions: sbomsByRevisionAdapter.getInitialState(),
});

export const sbomsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    createAsyncReducers(builder, entities, asyncAdapter);
  },
});

const globalizedAsyncSelectors = asyncAdapter.getSelectors<RootState>(
  state => state.sboms,
);

const globalizedLatestSelectors =
  sbomsLatestRevisionAdapter.getSelectors<RootState>(
    state => state.sboms.latest,
  );

const globalizedRevisionsSelectors =
  sbomsByRevisionAdapter.getSelectors<RootState>(
    state => state.sboms.revisions,
  );

const selectPagination = (state: RootState, groupId?: string) =>
  groupId ? state.views.sbomGroupSBOMsPagination : state.views.sbomsPagination;

const selectIdsByPage = createSelector(
  [selectPagination],
  ({ page, pageIds }) => pageIds[page],
);

const selectMany = createSelector(
  [
    (_: RootState, ids: string[]) => ids,
    globalizedLatestSelectors.selectAll,
    globalizedRevisionsSelectors.selectAll,
  ],
  (ids, latest, revisions) => {
    if (!ids) return [];

    const revisionIds = latest.flatMap(({ uuid, revisionUuid }) => {
      if (ids.includes(uuid)) return revisionUuid;

      return [];
    });

    return revisions
      .filter(({ revisionUuid }) => revisionIds.includes(revisionUuid))
      .sort((a, b) => ids.indexOf(a.uuid) - ids.indexOf(b.uuid));
  },
);

const selectManyByPage = (state: RootState, groupId?: string) =>
  selectMany(state, selectIdsByPage(state, groupId));

const selectById = createSelector(
  [
    (state: RootState, sbomId: string) => {
      const revisionId = globalizedLatestSelectors.selectById(
        state,
        sbomId,
      )?.revisionUuid;
      return revisionId
        ? globalizedRevisionsSelectors.selectById(state, revisionId)
        : undefined;
    },
    (state: RootState, _, revisionId?: string) =>
      revisionId
        ? globalizedRevisionsSelectors.selectById(state, revisionId)
        : undefined,
  ],
  (latest, revision) => revision ?? latest,
);

export const sbomsSelectors = {
  ...globalizedAsyncSelectors,
  selectById,
  selectMany,
  selectManyByPage,
  selectIdsByPage,
  selectPagination,
  latest: globalizedLatestSelectors,
  revisions: globalizedRevisionsSelectors,
};

export default sbomsSlice.reducer;
