/* eslint-disable import/prefer-default-export */
import semver from 'semver';

import metadata from '@/../package.json';
/**
 * Generates a URL to the Anchore documentation site, optionally with a path
 * When uiVersion is available, it will be used to determine the version of the
 * documentation to link to. If not, it will default to the 'current' version.
 * @param path
 * @returns docs URL
 */
export const getDocsUrl = (path?: string): string => {
  // eslint-disable-next-line local-rules/no-hardcoded-documentation-urls
  const baseDocsUrl = 'https://docs.anchore.com';
  const uiVersion = process.env.BROWSER
    ? window.App.uiVersion
    : metadata.version;

  const docsVersion =
    uiVersion && semver.valid(uiVersion)
      ? `${semver.major(uiVersion)}.${semver.minor(uiVersion)}`
      : 'current';

  const sanitizedPath = path
    ? `${path.replace(/\/+/g, '/').replace(/^\/|\/$/g, '')}/`
    : '';

  const parsedUrl = `${baseDocsUrl}/${docsVersion}/${sanitizedPath}`;

  return parsedUrl;
};
