import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Image } from 'semantic-ui-react';

import { getDocsUrl } from '@routes/utils';

import type { StepsConfig } from '../tourConfig';

import s from './TourSteps.scss';

const stepStyles = {
  options: {
    width: '65rem',
  },
};

const robotEl = (
  <Image src="/img/robot_telescope.svg" className={s.robotTelescopeImg} />
);

const Step1 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        The <strong>Kubernetes</strong> view allows you to check the compliance
        status of your clusters and namespaces. Data shown here is generated
        using the Kubernetes Runtime Inventory agent which is a lightweight
        read-only agent that runs in your clusters and sends data to the Anchore
        API.
      </p>
    </div>
  );
};

const Step2 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        You can drill down from the cluster to the namespace to the individual
        SBOM generated from the cluster. Note that the visualization and
        associated data are not a real-time view but are sourced from the data
        generated by the runtime reports.
      </p>
    </div>
  );
};

const Step3 = () => {
  useStyles(s);
  return (
    <div className={classNames(s.root, s.alignLeft)}>
      {robotEl}
      <p>
        To add a cluster via the Kubernetes Runtime Inventory agent, please read
        the{' '}
        <a
          href={getDocsUrl('/docs/integration/kubernetes/')}
          target="_blank"
          rel="noreferrer"
        >
          Kubernetes integration documentation
        </a>
        .
      </p>
    </div>
  );
};

export default (): StepsConfig => ({
  id: 'kubernetes',
  steps: [
    {
      styles: stepStyles,
      content: <Step1 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step2 />,
      placement: 'center',
      target: 'body',
    },
    {
      styles: stepStyles,
      content: <Step3 />,
      placement: 'center',
      target: 'body',
    },
  ],
});
