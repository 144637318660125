import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

/**
 * Creates a new Apollo Client instance, configured to provide the same setup as
 * `apollo-boost` without being bound to it as a dependency, offering more
 * flexibility.
 *
 * See https://www.apollographql.com/docs/react/v2/migrating/boost-migration
 */
const createApolloClient = (uri: string) =>
  new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.error(`[Network error]: ${networkError}`);
      }),
      new HttpLink({
        uri,
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache(),
  });

export interface ApolloClientProviderProps {
  uri: string;
}

/**
 * A pre-configured Apollo Client provider, which creates a new client using the
 * given URI and provides it to the rest of the application.
 */
export const ApolloClientProvider = ({
  children,
  uri,
}: PropsWithChildren<ApolloClientProviderProps>) => {
  const clientRef = useRef<ApolloClient<NormalizedCacheObject>>();

  if (!clientRef.current) {
    clientRef.current = createApolloClient(uri);
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
